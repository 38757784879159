* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  width: 100%;
  height: 100vh;
  text-align: center;
  background: #101010;
  color: white;
  font-size: 1.5rem;
  .navbar {
    background-color: #222;
    width: 100%;
    height: 50px;
    padding: 0;
    margin: 0;
    ul {
      height: 100%;
      li {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 33%;
        height: 100%;
        a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}
