.result {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  p {
    display: flex;
    flex-direction: row;
  }
  .user {
    color: #8739f9;
    margin-left: 10px;
  }
}
