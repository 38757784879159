.helloUser {
  display: flex;
  justify-content: center;
  p {
    display: flex;
    flex-direction: row;
  }
  .user {
    color: #8739f9;
    margin-left: 10px;
  }
}
.card-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: 100px;
  position: fixed;
  .cards {
    width: 500px;
    height: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
