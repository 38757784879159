.playing {
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    background: linear-gradient(to bottom, #8639f9 5%, #9463e2 100%);
    background-color: #8639f9;
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 17px;
    font-weight: bold;
    padding: 12px 44px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #000000;
    margin-top: 20px;
  }
}
