.card {
  width: 100px;
  height: auto;
  margin: 5px;
  img.card-open {
    width: 100%;
    height: 169.09px;
    transform: rotateY(180deg);
    transform-style: preserve-3d;
    transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  img.card-closed {
    width: 100%;
    border: 1px solid #8739f9;
    background: white;
    transform-style: preserve-3d;
    transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}
