.home-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  input {
    width: 20%;
    height: 5%;
    border-radius: 5px;
    outline: 0;
    margin: 0;
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    color: #8739f9;
  }
  a {
    background: linear-gradient(to bottom, #8639f9 5%, #9463e2 100%);
    background-color: #8639f9;
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 17px;
    font-weight: bold;
    padding: 12px 44px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #000000;
    margin-top: 20px;
  }
  ::placeholder {
    color: #8739f9;
  }

  a:hover {
    background: linear-gradient(to bottom, #a67ee8 5%, #8639f9 100%);
    background-color: #a67ee8;
  }
}
